import { schedulinglist, createscheduling, editscheduling, deletescheduling } from '@/api/ceshi'
import clinic from '@/api/clinic'
import { generateCALEMDAR } from '@/router/routes/ui-elements/genreateroutes'
export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
    selectedClinic: [],
  },
  getters: {},
  mutations: {
    SET_CALEMDAR(state, data) {
      state.calendarOptions = data
    },
  
    SET_SELECTED(state, data) {
      state.selectedCalendars = data
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_CLINIC(state, val) {
      state.selectedClinic = val
    },
  },
  actions: {
    fetchclinic({commit}) {
      return new Promise((resolve, reject) => {
        clinic.list().then(response =>{
          const rolelist = response.data.data
          const newArr = rolelist.map(item => ({ label: item.clinicname, color: 'primary'}));
          const newArr1 = rolelist.map(item => (item.clinicname));
          const newArr2 = rolelist.map(item => ({ label: item.clinicname, value: item._id}));
          commit('SET_CALEMDAR',newArr)
          commit('SET_SELECTED',newArr1)
          commit('SET_SELECTED_CLINIC',newArr2)
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        schedulinglist(calendars).then(response =>{
          resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    // fetchEvents(ctx, { calendars }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/calendar/events', {
    //         params: {
    //           calendars: calendars.join(','),
    //         },
    //       })
    //       .then(response => {
    //         console.log(response)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    addEvent(ctx, { event }) {
      console.log("添加")
      return new Promise((resolve, reject) => {
        createscheduling(event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      console.log("gengxin")
      return new Promise((resolve, reject) => {
        editscheduling(event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        deletescheduling(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
